import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Stack, MenuItem } from '@mui/material';
import axios from 'axios';

const RUNPOD_API_URL = "https://runmanagement-main.onrender.com";

const PodModal = ({ fetchPods, showSnackbar }) => {
    const [open, setOpen] = useState(false);
    const [gpuTypes, setGpuTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        template_id: 'q5vu8pourl',
        name: 'Fooocus',
        gpu_count: 1,
        cloud_type: 'COMMUNITY',
        image_name: 'ishworrsubedii/fooocus-custom:latest',
        port: '7865',
        disk_size: 100,
        volume: 5,
        gpu_type_id: {
            id: "NVIDIA RTX A4000",
            label: "RTX A4000 (16GB)"
        }
    });

    const fetchGpuTypes = useCallback(async () => {
        const currentToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
        const currentAccessToken = currentToken?.access_token;

        if (!currentAccessToken) {
            showSnackbar('Authentication token not found', 'error');
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.get(`${RUNPOD_API_URL}/list_gpu`, {
                headers: {
                    'Authorization': `Bearer ${currentAccessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                },
            });
            const gpuTypes = response?.data?.map((gpu) => ({
                id: gpu.id,
                label: `${gpu.displayName} (${gpu.memoryInGb}GB)`
            }));
            setGpuTypes(gpuTypes);
        } catch (error) {
            console.error('Error fetching GPU types:', error);
            showSnackbar('Failed to fetch GPU types', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [showSnackbar]);

    useEffect(() => {
        if (open) {
            fetchGpuTypes();
        }
    }, [open, fetchGpuTypes]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'gpu_type_id') {
            const selectedGpu = gpuTypes.find(gpu => gpu.id === value);
            setFormData(prev => ({
                ...prev,
                [name]: {
                    id: selectedGpu.id,
                    label: selectedGpu.label
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const currentToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
        const currentAccessToken = currentToken?.access_token;

        if (!currentAccessToken) {
            showSnackbar('Authentication token not found', 'error');
            return;
        }

        try {
            const formattedData = {
                ...formData,
                gpu_type_id: formData.gpu_type_id.id
            };

            await axios.post(`${RUNPOD_API_URL}/pods`, formattedData, {
                headers: {
                    'Authorization': `Bearer ${currentAccessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            setOpen(false);
            fetchPods();
            showSnackbar('Pod created successfully');
        } catch (error) {
            console.error('Error creating pod:', error);
            showSnackbar('You cannot create more than 1 pods; please delete one before creating another', 'error');
        }
    };

    return (
        <>
            <Button variant="contained" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
                Create New Pod
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Create New Pod</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <TextField
                            name="template_id"
                            label="Template ID"
                            value={formData.template_id}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="name"
                            label="Name"
                            value={formData.name}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="gpu_count"
                            label="GPU Count"
                            type="number"
                            value={formData.gpu_count}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="cloud_type"
                            label="Cloud Type"
                            value={formData.cloud_type}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="image_name"
                            label="Image Name"
                            value={formData.image_name}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="port"
                            label="Port"
                            value={formData.port}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="disk_size"
                            label="Disk Size"
                            type="number"
                            value={formData.disk_size}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            name="volume"
                            label="Volume"
                            type="number"
                            value={formData.volume}
                            onChange={handleChange}
                            fullWidth
                        />
                        <TextField
                            select
                            name="gpu_type_id"
                            label="GPU Type"
                            value={formData.gpu_type_id.id}
                            onChange={handleChange}
                            fullWidth
                        >
                            {gpuTypes.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PodModal;