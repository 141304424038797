import React from 'react';
import {
  Container,
  Paper,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Alert,
} from '@mui/material';
import {
  ExpandMore,
  PlayArrow,
  Timer,
  Warning,
  Info,
  Delete,
  Image,
  Settings,
} from '@mui/icons-material';

const Help = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: 2,
          bgcolor: 'background.paper',
          boxShadow: '0 2px 16px rgba(0,0,0,0.08)',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            fontWeight: 600,
            mb: 4,
            background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          }}
        >
          Help Center
        </Typography>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Getting Started</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon><PlayArrow color="success" /></ListItemIcon>
                <ListItemText 
                  primary="Step 1: Start Your Pod"
                  secondary="Navigate to Pod Management and start your pod. This initializes the backend server."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><Timer color="warning" /></ListItemIcon>
                <ListItemText 
                  primary="Step 2: Wait for Initialization"
                  secondary="Allow 4-5 minutes for the system to fully initialize and load all components."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><Image color="primary" /></ListItemIcon>
                <ListItemText 
                  primary="Step 3: Access Image Generator"
                  secondary="Once initialized, go to the Image Generator page to access the Gradio application."
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Important Notes</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Alert severity="warning" sx={{ mb: 2 }}>
              If the pod doesn't load after 20-30 minutes, it may be due to RunPod GPU allocation issues.
              In this case, you'll need to create a new pod.
            </Alert>
            <Alert severity="info" sx={{ mb: 2 }}>
              Deleting a pod will result in the loss of:
              <List>
                • All downloaded custom models
                • Image generation history
                • Custom settings and configurations
              </List>
            </Alert>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Troubleshooting</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              <ListItem>
                <ListItemIcon><Warning color="error" /></ListItemIcon>
                <ListItemText 
                  primary="Pod Not Starting"
                  secondary="If your pod fails to start, try deleting it and creating a new one with different GPU options."
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><Settings /></ListItemIcon>
                <ListItemText 
                  primary="Interface Not Loading"
                  secondary="Try refreshing the interface using the refresh button at the top of the Image Generator page."
                />
              </ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Container>
  );
};

export default Help; 