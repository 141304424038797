import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Card,
  CardContent,
  CircularProgress,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import supabase from '../supabaseClient/supabase';

const Analytics = () => {
  const [usageLogs, setUsageLogs] = useState([]);
  const [userStats, setUserStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userDurations, setUserDurations] = useState({});

  console.log('usageLogs', usageLogs);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (usageLogs.length > 0) {
      processUserDurations();
    }
  }, [usageLogs]);

  const fetchData = async () => {
    try {
      const { data: profiles, error: profilesError } = await supabase
        .from('user_profiles')
        .select('*');

      if (profilesError) throw profilesError;
      setUserStats(profiles);
      console.log('Fetched user profiles:', profiles);

      const { data: activities, error: activitiesError } = await supabase
        .from('pod_activities')
        .select(`
          *,
          user_profiles:user_id (
            full_name,
            email
          )
        `)
        .order('timestamp', { ascending: false });

      if (activitiesError) throw activitiesError;
      setUsageLogs(activities);
      console.log('Fetched pod activities:', activities);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const processUserDurations = () => {
    const uniqueUserIds = [...new Set(usageLogs.map(log => log.user_id))];

    const userActivities = uniqueUserIds.reduce((acc, userId) => {
      const userLogs = usageLogs
        .filter(log => log.user_id === userId)
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      acc[userId] = userLogs;
      return acc;
    }, {});

    const durations = uniqueUserIds.reduce((acc, userId) => {
      const logs = userActivities[userId];
      let totalDuration = 0;

      for (let i = 0; i < logs.length - 1; i++) {
        if (logs[i].pod_status === 'RUNNING' && logs[i + 1].pod_status === 'EXITED') {
          const start = new Date(logs[i].timestamp);
          const end = new Date(logs[i + 1].timestamp);
          totalDuration += end - start;
        }
      }

      acc[userId] = {
        duration: totalDuration,
        email: logs[0].user_profiles.email,
        fullName: logs[0].user_profiles.full_name
      };
      return acc;
    }, {});

    setUserDurations(durations);
  };

  const getActivityData = () => {
    const activityCounts = usageLogs.reduce((acc, log) => {
      acc[log.action_type] = (acc[log.action_type] || 0) + 1;
      return acc;
    }, {});

    return Object.entries(activityCounts).map(([action, count]) => ({
      name: action,
      value: count,
    }));
  };

  const getUserActivityData = () => {
    return Object.entries(userDurations).map(([userId, data]) => {
      const userLogs = usageLogs.filter(log => log.user_id === userId);
      return {
        name: data.fullName || data.email,
        duration: Number((data.duration / (1000 * 60)).toFixed(1)),
        starts: userLogs.filter(log => log.action_type === 'START').length,
        stops: userLogs.filter(log => log.action_type === 'STOP').length
      };
    });
  };

  const formatDuration = (milliseconds) => {
    const minutes = Math.floor(milliseconds / (1000 * 60));
    if (minutes >= 60) {
      const hours = (minutes / 60).toFixed(1);
      return `${hours}h`;
    }
    return `${minutes}m`;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Users</Typography>
              <Typography variant="h4">{userStats.length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Activities</Typography>
              <Typography variant="h4">{usageLogs.length}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6">Active Pods</Typography>
              <Typography variant="h4">
                {usageLogs.filter((log) => log.pod_status === 'RUNNING').length}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Charts */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Activity Distribution
            </Typography>
            <PieChart width={400} height={300}>
              <Pie
                data={getActivityData()}
                cx={200}
                cy={150}
                labelLine={false}
                label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {getActivityData().map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={['#0088FE', '#00C49F', '#FFBB28', '#FF8042'][index % 4]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              User Activity
            </Typography>
            <BarChart
              width={400}
              height={300}
              data={getUserActivityData()}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip formatter={(value, name) => {
                if (name === "Duration") {
                  return formatDuration(value * 60 * 1000);
                }
                return value;
              }} />
              <Legend />
              <Bar
                dataKey="duration"
                fill="#ff7300"
                name="Duration"
              />
              <Bar dataKey="starts" fill="#8884d8" name="Pod Starts" />
              <Bar dataKey="stops" fill="#82ca9d" name="Pod Stops" />
            </BarChart>
          </Paper>
        </Grid>

        {/* Activity Table */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activities
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Pod ID</TableCell>
                  <TableCell>Pod Status</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usageLogs
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log) => (
                    <TableRow key={log.id}>
                      <TableCell>
                        {log.user_profiles?.full_name || log.user_profiles?.email || 'N/A'}
                      </TableCell>
                      <TableCell>{log.action_type}</TableCell>
                      <TableCell>{log.pod_id}</TableCell>
                      <TableCell>{log.pod_status}</TableCell>
                      <TableCell>
                        {new Date(log.timestamp).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={usageLogs.length}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Analytics; 