import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../supabaseClient/supabase';
import axios from 'axios';

export const useInactivityReset = (timeout = 60000) => {
    const navigate = useNavigate();
    const authToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
    const accessToken = authToken?.access_token;
    const RUNPOD_API_URL = "https://runmanagement-main.onrender.com";

    const stopActivePods = async () => {
        try {
            const response = await axios.get(`${RUNPOD_API_URL}/pods`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true'
                }
            });

            const runningPods = response.data.filter(pod => pod.status === 'RUNNING');

            for (const pod of runningPods) {
                await axios.post(`${RUNPOD_API_URL}/pods/stop?pod_id=${pod.id}`, null, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
            }
        } catch (error) {
            console.error('Error stopping pods:', error);
        }
    };

    const handleInactivityLogout = useCallback(async () => {
        try {
            await stopActivePods();
            await signOut();
            localStorage.removeItem('sb-tzoegtoebvpadnufwnlj-auth-token');
            localStorage.removeItem('token');
            localStorage.removeItem('user_info');
            localStorage.clear();
            navigate('/login');
        } catch (error) {
            console.error('Error during inactivity logout:', error);
            navigate('/login');
        }
    }, [navigate, accessToken]);

    useEffect(() => {
        let timeoutId;

        const resetTimer = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(handleInactivityLogout, timeout);
        };

        const events = [
            'mousedown',
            'mousemove',
            'keypress',
            'scroll',
            'touchstart',
            'click'
        ];

        events.forEach(event => {
            document.addEventListener(event, resetTimer);
        });

        resetTimer();

        return () => {
            clearTimeout(timeoutId);
            events.forEach(event => {
                document.removeEventListener(event, resetTimer);
            });
        };
    }, [timeout, handleInactivityLogout]);
}; 