import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Badge,
  Tooltip,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { signOut } from '../../supabaseClient/supabase';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '../../context/ThemeContext';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const { isDarkMode, toggleTheme } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    setLogoutDialog(true);
  };

  const handleLogoutCancel = () => {
    setLogoutDialog(false);
  };

  const handleLogoutConfirm = async () => {
    try {
      setLoggingOut(true);
      const { error } = await signOut();
      if (!error) {
        localStorage.clear();
        navigate('/login');
      }
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoggingOut(false);
      setLogoutDialog(false);
    }
  };

  const isActive = (path) => location.pathname === path;

  const navItems = [
    { path: '/home', label: 'Image Generator', icon: <ImageIcon /> },
    { path: '/dashboard', label: 'Pod Management', icon: <DashboardIcon /> },
    { path: '/pod-setup', label: 'Pod Setup', icon: <SettingsIcon /> },
    { path: '/help', label: 'Help', icon: <HelpOutlineIcon /> },
  ];

  const handleHelpClick = () => {
    navigate('/help');
  };

  return (
    <>
      <AppBar 
        position="static" 
        elevation={1}
        sx={{
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters sx={{ minHeight: '64px' }}>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                color: 'primary.main',
                fontWeight: 600,
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
             
              EasyQuickAI
            </Typography>

            {!user ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/login')}
                startIcon={<AccountCircleIcon />}
              >
                Login
              </Button>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {navItems.map((item) => (
                  <Button
                    key={item.path}
                    variant="text"
                    color="primary"
                    onClick={() => navigate(item.path)}
                    startIcon={item.icon}
                    sx={{
                      px: 2,
                      py: 1,
                      borderRadius: 1,
                      backgroundColor: isActive(item.path) ? 'action.selected' : 'transparent',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                    }}
                  >
                    {item.label}
                  </Button>
                ))}

                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                <Tooltip title="Toggle theme">
                  <IconButton onClick={toggleTheme} color="primary" size="small">
                    {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Account">
                  <IconButton
                    onClick={handleMenuOpen}
                    size="small"
                    sx={{ ml: 1 }}
                  >
                    <Avatar
                      sx={{
                        width: 32,
                        height: 32,
                        bgcolor: 'primary.main',
                        fontSize: '1rem'
                      }}
                    >
                      {userInfo?.full_name?.charAt(0) || 'U'}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Box sx={{ px: 2, py: 1 }}>
                    <Typography variant="subtitle2" color="text.primary">
                      {userInfo?.full_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {userInfo?.email}
                    </Typography>
                  </Box>
                  <Divider />
                  <MenuItem onClick={handleMenuClose}>
                    <AccountCircleIcon sx={{ mr: 1 }} /> Profile
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <SettingsIcon sx={{ mr: 1 }} /> Settings
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={handleLogoutClick} sx={{ color: 'error.main' }}>
                    <LogoutIcon sx={{ mr: 1 }} /> Logout
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <Dialog
        open={logoutDialog}
        onClose={handleLogoutCancel}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ 
          pb: 1,
          background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
          color: 'white'
        }}>
          Confirm Logout
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography>
            Are you sure you want to log out? Any unsaved changes will be lost.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button 
            onClick={handleLogoutCancel} 
            disabled={loggingOut}
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            color="error"
            variant="contained"
            disabled={loggingOut}
            startIcon={loggingOut ? <CircularProgress size={20} /> : <LogoutIcon />}
          >
            {loggingOut ? 'Logging out...' : 'Logout'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;