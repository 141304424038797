import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import supabase from "../supabaseClient/supabase";
import UsernameDialog from '../components/auth/UsernameDialog';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [showUsernameDialog, setShowUsernameDialog] = useState(false);
  const navigate = useNavigate();

  console.log("localhost", localStorage);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const { data: { user }, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // Check if user has a full name
      if (!user.user_metadata?.full_name) {
        setShowUsernameDialog(true);
        return;
      }

      await completeLogin(user);
    } catch (error) {
      console.error('Error during login:', error);
      setError(error.message);
    }
  };

  const completeLogin = async (user) => {
    try {
      // Store additional user info in localStorage
      const userInfo = {
        id: user.id,
        email: user.email,
        full_name: user.user_metadata.full_name,
        created_at: user.created_at
      };
      localStorage.setItem('user_info', JSON.stringify(userInfo));

      // Create/update user profile in Supabase
      const { error: profileError } = await supabase
        .from('user_profiles')
        .upsert({
          id: user.id,
          email: user.email,
          full_name: user.user_metadata.full_name,
          total_pod_starts: 0,
          total_pod_stops: 0,
          last_login: new Date().toISOString()
        });

      if (profileError) throw profileError;
      navigate('/');
    } catch (error) {
      console.error('Error completing login:', error);
      setError(error.message);
    }
  };

  const handleUsernameSubmit = async (fullName) => {
    try {
      const { data: { user }, error } = await supabase.auth.updateUser({
        data: { full_name: fullName }
      });

      if (error) throw error;

      await completeLogin(user);
      setShowUsernameDialog(false);
    } catch (error) {
      console.error('Error updating username:', error);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 3,
            width: "100%",
            borderRadius: 2,
            bgcolor: "background.paper",
            boxShadow: "0 2px 16px rgba(0,0,0,0.08)",
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            align="center"
            sx={{
              fontWeight: 600,
              background: "linear-gradient(to right, #2563eb, #1d4ed8)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              mb: 1,
            }}
          >
            EasyQuickAI
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="text.secondary"
            sx={{ mb: 3 }}
          >
            Welcome back
          </Typography>
          {error && (
            <Typography
              color="error"
              variant="body2"
              align="center"
              sx={{ mb: 2 }}
            >
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              type="email"
              margin="normal"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              size="small"
              label="Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="small"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              sx={{ mt: 3, mb: 2, py: 1.2 }}
            >
              Sign in
            </Button>
          </form>
          <Typography variant="body2" align="center" color="text.secondary">
            Don't have an account?{" "}
            Please Contact Administration to get access.
          </Typography>
        </Paper>
      </Box>
      <UsernameDialog
        open={showUsernameDialog}
        onClose={() => setShowUsernameDialog(false)}
        onSubmit={handleUsernameSubmit}
      />
    </Container>
  );
};

export default Login;
