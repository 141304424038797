import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';

const IdleWarningDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Inactivity Warning</DialogTitle>
      <DialogContent>
        <Typography>
          Your pod will be turned off due to inactivity in 5 minutes. Are you still there?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Let it stop
        </Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Yes, keep it running
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleWarningDialog; 