import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Alert,
  AlertTitle,
  Backdrop
} from '@mui/material';
import { AccessTime } from '@mui/icons-material';

const InitialLoadingOverlay = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000); // Show for 3 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!show) return null;

  return (
    <Backdrop
      open={show}
      sx={{
        zIndex: theme => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(4px)',
      }}
    >
      <Box sx={{ 
        maxWidth: 500,
        width: '90%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}>
        <Alert 
          severity="info" 
          icon={<AccessTime sx={{ fontSize: 28 }} />}
          sx={{ 
            boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
            '& .MuiAlert-message': { width: '100%' }
          }}
        >
          <AlertTitle sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
            Welcome to the Dashboard
          </AlertTitle>
          <Typography>
            The backend services are initializing and may take up to 50 seconds to be ready.
            If something doesn't work, please wait a moment and try again.
          </Typography>
        </Alert>
        <LinearProgress 
          sx={{ 
            height: 6,
            borderRadius: 3,
            bgcolor: 'rgba(0,0,0,0.05)',
          }} 
        />
      </Box>
    </Backdrop>
  );
};

export default InitialLoadingOverlay; 