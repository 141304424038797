import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Typography,
  Container,
  Paper,
  Button,
} from "@mui/material";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { signOut } from '../supabaseClient/supabase';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Settings, HelpOutline } from '@mui/icons-material';
import IdleWarningDialog from '../components/IdleWarningDialog';

const StatusIndicator = ({ status }) => {
  const isRunning = status === 'RUNNING';
  
  return (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 1,
      bgcolor: 'background.paper',
      py: 0.5,
      px: 2,
      borderRadius: 1,
      border: 1,
      borderColor: 'divider'
    }}>
      <Box
        sx={{
          width: 8,
          height: 8,
          borderRadius: '50%',
          bgcolor: isRunning ? '#4caf50' : '#f44336',
          boxShadow: `0 0 6px ${isRunning ? '#4caf50' : '#f44336'}`,
        }}
      />
      <Typography 
        variant="body2" 
        sx={{ 
          color: isRunning ? 'success.main' : 'error.main',
          fontWeight: 500
        }}
      >
        Backend {isRunning ? 'Running' : 'Not Running'}
      </Typography>
    </Box>
  );
};

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [podStatus, setPodStatus] = useState(null);
  const [gradioUrl, setGradioUrl] = useState('');
  const [POD_ID, setPOD_ID] = useState('');
  const PORT = '7865';
  const navigate = useNavigate();
  
  const authToken = JSON.parse(localStorage.getItem('sb-tzoegtoebvpadnufwnlj-auth-token'));
  const accessToken = authToken?.access_token;

  const [iframeKey, setIframeKey] = useState(0);
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const [idleCheckInterval, setIdleCheckInterval] = useState(null);

  const handleLogout = async () => {
    try {
      await signOut();
      localStorage.removeItem('sb-tzoegtoebvpadnufwnlj-auth-token');
      localStorage.removeItem('token');
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      navigate('/login');
    }
  };

  const checkSession = () => {
    if (!authToken) {
      handleLogout();
      return false;
    }

    const expiresAt = new Date(authToken.expires_at * 1000); // Convert to milliseconds
    const currentTime = new Date();

    if (currentTime >= expiresAt) {
      handleLogout();
      return false;
    }
    return true;
  };

  const checkPodStatus = async () => {
    if (!checkSession()) return;

    try {
      const response = await axios.get(`https://runmanagement-main.onrender.com/pods`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': 'application/json'
        }
      });
      const pods = response.data;
      if (pods.length > 0) {
        const podId = pods[0].id;
        setPOD_ID(podId);
        setGradioUrl(`https://${podId}-${PORT}.proxy.runpod.net`);
      }
      setPodStatus(pods[0]);
    } catch (err) {
      console.error('Failed to fetch pod status:', err);
      if (err.response?.status === 401) {
        handleLogout();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshIframe = () => {
    setIframeKey(prevKey => prevKey + 1);
  };

  const handleKeepAlive = async () => {
    try {
      await axios.get(`https://runmanagement-main.onrender.com/pods`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'accept': 'application/json'
        }
      });
      setShowIdleWarning(false);
      resetIdleTimer();
    } catch (err) {
      console.error('Failed to keep pod alive:', err);
    }
  };

  const resetIdleTimer = () => {
    if (idleCheckInterval) {
      clearInterval(idleCheckInterval);
    }
    
    const newInterval = setInterval(() => {
      setShowIdleWarning(true);
    }, 25 * 60 * 1000); // 25 minutes
    
    setIdleCheckInterval(newInterval);
  };

  useEffect(() => {
    if (checkSession()) {
      checkPodStatus();
      resetIdleTimer();
      
      const statusInterval = setInterval(() => {
        if (checkSession()) {
          checkPodStatus();
        }
      }, 30000);

      return () => {
        clearInterval(statusInterval);
        if (idleCheckInterval) {
          clearInterval(idleCheckInterval);
        }
      };
    }
  }, []);

  const handleIdleWarningClose = () => {
    setShowIdleWarning(false);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      {/* Status Bar */}
      <Box 
        sx={{ 
          width: '100%', 
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          px: 3,
          py: 1.5,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          mb: 3
        }}
      >
        <Button
          onClick={checkPodStatus}
          startIcon={<RefreshIcon />}
          size="small"
          disabled={loading}
        >
          Refresh Status
        </Button>
        {loading ? (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            bgcolor: 'background.paper',
            py: 0.5,
            px: 2,
            borderRadius: 1,
            border: 1,
            borderColor: 'divider'
          }}>
            <CircularProgress size={16} />
            <Typography variant="body2">
              Checking status...
            </Typography>
          </Box>
        ) : (
          <StatusIndicator status={podStatus?.status} />
        )}
      </Box>

      {/* Main Content with iFrame */}
      <Paper 
        elevation={0}
        sx={{ 
          p: 0,
          borderRadius: 2,
          bgcolor: 'background.paper',
          boxShadow: '0 2px 16px rgba(0,0,0,0.08)',
          height: 'calc(100vh - 180px)',
          overflow: 'hidden'
        }}
      >
        {podStatus?.status === 'RUNNING' && gradioUrl ? (
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 1, borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                onClick={handleRefreshIframe}
                startIcon={<RefreshIcon />}
                size="small"
              >
                Refresh Interface
              </Button>
            </Box>
            <iframe
              key={iframeKey}
              src={gradioUrl}
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              title="Fooocus Interface"
            />
          </Box>
        ) : (
          <Box 
            sx={{ 
              height: '100%', 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 3
            }}
          >
            <Typography variant="h6" color="text.secondary">
              {loading ? 'Loading...' : 'Backend is not running'}
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center">
              To start generating images, you'll need to:
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/dashboard')}
                startIcon={<Settings />}
              >
                Pod Management
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/help')}
                startIcon={<HelpOutline />}
              >
                Help Guide
              </Button>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              Need help? Check our help guide for detailed instructions
            </Typography>
          </Box>
        )}
      </Paper>
      <IdleWarningDialog
        open={showIdleWarning}
        onClose={handleIdleWarningClose}
        onConfirm={handleKeepAlive}
      />
    </Container>
  );
};

export default Home;
