import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';

const UsernameDialog = ({ open, onClose, onSubmit }) => {
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!fullName.trim()) {
      setError('Please enter your full name');
      return;
    }
    onSubmit(fullName);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ 
        pb: 1,
        background: 'linear-gradient(to right, #2563eb, #1d4ed8)',
        color: 'white'
      }}>
        Welcome to EasyQuickAI
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please enter your full name to complete your profile:
        </Typography>
        <TextField
          autoFocus
          fullWidth
          label="Full Name"
          value={fullName}
          onChange={(e) => {
            setFullName(e.target.value);
            setError('');
          }}
          error={!!error}
          helperText={error}
          sx={{ mb: 2 }}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{ py: 1 }}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UsernameDialog; 